import * as React from "react"
import styled from "styled-components"

import Layout from "../components/Layout/layout"
import Seo from "../components/Seo/seo"
import ImageTextBlock from "../components/ImageTextBlock/imageTextBlock"
import { H1 } from "../styles/typography"
import { main } from "../styles/theme"

const HeadingWrapper = styled.div`
  margin: 46px 0 0;

  @media ${main.breakpoints.large} {
    margin: 196px 0 0;
  }
`

const Kopanmalan = () => (
  <Layout hasForm={false} invertedScrollToTop={true}>
    <Seo title="Digital köpanmälan" />
    <HeadingWrapper>
      <H1 size="large">Digital köpanmälan</H1>
    </HeadingWrapper>
    <ImageTextBlock
      text={[
        `Köpanmälan kan inte göras för tillfället`,
        // `Vi är glada att få erbjuda dig förtur till en bostad i Smedjan, innan den officiella säljstarten.
        // Skriv in i formuläret nedan vilken eller vilka bostäder du önskar, i prioritetsordning ett till tre. I rutan med meddelande fyller du i eventuella andra önskemål.`,
        // `Efter att köpanmälan har stängt kommer mäklaren kontakta alla anmälningar i den turordning man lämnade in sin intresseanmälan för projektet, antingen på 4D Gruppens hemsida eller Smedjans nya hemsida.`,
        // `När det är din tur får du välja bostad utifrån den köpanmälan du gjort och vilka av dina val som är lediga. Är ingen av de tre bostäderna som du anmält intresse för tillgängliga har du istället möjlighet att välja en annan, då det är din tur när mäklaren kontaktar dig.`,
        // `Efter detta är det dags att teckna ett förhandsavtal. Detta avtal är förknippat med ett förskott om 50 000 kr som aviseras separat så snart föreningen erhållit tillstånd från Bolagsverket att ta emot förskott, preliminärt augusti 2021. Insatsen, med avdrag för erlagt förskott, ska inte betalas förrän på tillträdesdagen.`,
        // `Har du andra frågor? Givetvis finns mäklarna tillgängliga för att svara på dina funderingar och guida dig genom processen. Kontaktuppgifter hittar du längst ner på sidan.`
      ]}
      imagePosition="right"
      offset={1}
      columns={5}
    />
  </Layout>
)

export default Kopanmalan
