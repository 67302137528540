import React from "react"
import PropTypes from "prop-types"
import { Wrapper, TextContainer, ImageContainer, Buttons } from "./styles"
import { H2, NavLink, P, ExternalLink } from "../../styles/typography"

const ImageTextBlock = ({
  preHeading,
  text,
  imagePosition,
  offset,
  buttonTitle,
  buttonLink,
  externalLink,
  columns,
  marginBottom,
  customButtons,
  image
}) => {
  return (
    <Wrapper marginBottom={marginBottom}>
      {image &&
        <ImageContainer imagePosition={imagePosition}>
          {image}
        </ImageContainer>
      }
      <TextContainer
        hasPreHeading={preHeading}
        columns={columns}
        offset={offset}
        imagePosition={imagePosition}
      >
        {preHeading && <H2>{preHeading}</H2>}
        {text.map((section, index) =>
          <P key={index} size="large">{section}</P>
        )}
        {buttonTitle && buttonLink && (
          <NavLink to={buttonLink}>{buttonTitle}</NavLink>
        )}
        {buttonTitle && externalLink && (
          <ExternalLink href={externalLink} target="_blank" rel="noreferrer">{buttonTitle}</ExternalLink>
        )}
        {customButtons &&
          <Buttons>
            <NavLink hasborder="true" to="/ettor"><span>Mer om 1 rok</span></NavLink>
            <NavLink hasborder="true" to="/enochenhalvor"><span>Mer om 1,5 rok</span></NavLink>
            <NavLink hasborder="true" to="/tvaor"><span>Mer om 2 rok</span></NavLink>
            <NavLink hasborder="true" to="/treor"><span>Mer om 3 rok</span></NavLink>
            <NavLink hasborder="true" to="/fyror"><span>Mer om 4 rok</span></NavLink>
          </Buttons>
        }
      </TextContainer>
    </Wrapper>
  )
}

ImageTextBlock.propTypes = {
  text: PropTypes.array.isRequired,
  preHeading: PropTypes.string,
  imagePosition: PropTypes.string,
  offset: PropTypes.number,
  buttonTitle: PropTypes.string,
  buttonLink: PropTypes.string,
  externalLink: PropTypes.string,
  columns: PropTypes.number,
  marginBottom: PropTypes.string,
  customButtons: PropTypes.bool,
  image: PropTypes.element
}

ImageTextBlock.defaultProps = {
  preHeading: "",
  imagePosition: "left",
  buttonTitle: "",
  buttonLink: "",
  offset: 1,
  columns: 5,
  marginBottom: 'normal',
  customButtons: false,
  image: null
}

export default ImageTextBlock
