import styled from "styled-components"
import { main } from "../../styles/theme"
import { gridLayout } from "../../styles/mixins"

export const Wrapper = styled.section`
  ${gridLayout}
  grid-auto-flow: dense;
  padding: ${({ marginBottom }) =>
      marginBottom === 'narrow' ? '30px 0' :  '30px 0 60px'};

  @media ${main.breakpoints.medium} {
    padding: ${({ marginBottom }) =>
      marginBottom === 'narrow' ? '63px 0 50px' :  '63px 0 124px'};
  }
`
export const ImageContainer = styled.div`
  margin-bottom: 40px;
  grid-column: 1 / -1;

  @media ${main.breakpoints.medium} {
    margin-bottom: 0;
    grid-column: ${({ imagePosition }) => imagePosition === 'left' ? '1 / span 6' : '7 / span 6'};
  }
`
export const TextContainer = styled.div`
  grid-column: 1 / -1;

  @media ${main.breakpoints.medium} {
    margin-top: ${({ hasPreHeading }) => (hasPreHeading ? "-11px" : "-8px")};
    grid-column: ${({ imagePosition, columns, offset }) =>
      imagePosition === 'left' ?
        `${offset + 6} / span ${columns}`
      : `${offset} / span ${columns}`};
  }

  > a {
    margin-top: 34px;
  }
`

export const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 5px;

  > a {
    width: 175px;
    margin: 0 24px 24px 0;
  }
`
